<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="user_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteUser()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.mansion") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="apartment_id"
                    rules="required"
                    :options-data="meta.apartments"
                    v-model="entry.apartment_id"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.facility") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="facility_id"
                    rules="required"
                    :options-data="meta.facilities"
                    v-model="entry.facility_id"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.time") }} <span class="app-require">＊</span></label>
                <div class="col-xl-2 col-lg-3 col-sm-3">
                  <app-select
                    name="opening_time"
                    :rules="`required|opening_time:${entry.closing_time}`"
                    :options-data="meta.opening_time"
                    v-model="entry.opening_time"
                  />
                </div>
                <div class="col-xl-1 col-lg-2 col-sm-2 text-center">
                  <span class="text-label">～</span>
                </div>
                <div class="col-xl-2 col-lg-3 col-sm-3">
                  <app-select
                    name="closing_time"
                    rules="required"
                    :options-data="meta.closing_time"
                    v-model="entry.closing_time"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.unit_time") }} <span class="app-require">＊</span></label>
                <div class="col-xl-2 col-lg-3 col-sm-3 col-unit mr-1 mr-lg-2">
                  <app-select
                    name="unit_time"
                    rules="required"
                    :options-data="meta.unit_time"
                    v-model="entry.unit_time"
                  />
                </div>
                <div class=" mt-2" style="font-weight: bold !important;color: black; text-align: right">
                  {{ $t("plans.min") }}
                </div>
              </div>
              <validation-provider
                name="work_days"
                rules="required"
                v-slot="{ errors, invalid, validated }"
                ref="work_days"
              >
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.work_days") }} <span class="app-require">＊</span></label>
                <div class="col-xl-6 col-lg-8 col-sm-8 gr-icheck">
                  <app-checkbox
                    :label="$t('book-masters.sunday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="sun"
                  />
                  <app-checkbox
                    :label="$t('book-masters.monday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="mon"
                  />
                  <app-checkbox
                    :label="$t('book-masters.tuesday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="tue"
                  />
                  <app-checkbox
                    :label="$t('book-masters.wednesday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="wed"
                  />
                  <app-checkbox
                    :label="$t('book-masters.thursday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="thu"
                  />
                  <app-checkbox
                    :label="$t('book-masters.friday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="fri"
                  />
                  <app-checkbox
                    :label="$t('book-masters.saturday')"
                    v-model="entry.work_days"
                    class="m-r-20"
                    val="sat"
                  />
                  <label v-if="invalid && validated" class="error">
                    {{ errors[0] }}
                  </label>
                </div>
              </div>
              </validation-provider>
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-xl-1-4 col-form-label">{{ $t("book-masters.unit_capacity") }} <span class="app-require">＊</span></label>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <app-input
                    name="unit_capacity"
                    type="number-length:4"
                    rules="required|min_value:1"
                    v-model="entry.unit_capacity"
                  />
                </div>
                <div class="col-xl-2 col-lg-2 col-sm-2">
                  <span class="text-label text-bold">/ 同一時間帯</span>
                </div>
              </div>
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-xl-1-4 col-form-label">{{ $t("book-masters.limit_booking_in_unit_time") }}</label>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <app-input
                    name="limit_booking_in_unit_time"
                    type="number-length:4"
                    rules="min_value:1"
                    v-model="entry.limit_booking_in_unit_time"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("book-masters.limit_booking_hours") }}</label>
                <div class="col-xl-1 col-lg-2 col-sm-2 error-text-align-justify">
                  <app-input
                    name="limit_booking_hours"
                    type="number-length:3"
                    rules="max_value:255|min_limit_booking_hour:1"
                    v-model="entry.limit_booking_hours"
                  />
                </div>
                <label class="col-xl-1 col-lg-1 col-sm-2 title-rh col-form-label">{{ $t("book-masters.limit_booking_times") }} </label>
                <div class="col-xl-1 col-lg-2 col-sm-2 error-text-align-justify">
                  <app-input
                    name="limit_booking_times"
                    type="number-length:3"
                    rules="max_value:255|min_limit_booking_times:1"
                    v-model="entry.limit_booking_times"
                  />
                </div>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <span class="text-label text-bold">回</span>
                </div>
              </div>
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-xl-1-4 col-form-label">館内施設数 <span class="app-require">＊</span></label>
                <div class="col-xl-1 col-lg-2 col-sm-2">
                  <app-input
                    name="space_number"
                    type="number-length:2"
                    rules="required|min_value:1"
                    v-model="entry.space_number"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>
              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDeleteBookMaster">
                  {{ $t('common.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
import AppRadio from "../../../components/forms/AppRadio";
import AppCheckbox from "../../../components/forms/AppCheckbox";
import {ROUTES} from "@constants";
export default {
  name: "BookMasterCreateOrEdit",

  components: {
    AppCheckbox,
    AppRadio
  },

  data() {
    const id = this.$route.params.id
    return {
      testInput: "",
      testSelect: "",
      radio: {
        block: "opton 1",
        inline: "",
        right: "",
      },
      checkbox: {
        block: ["opton 1", "option 2"],
        inline: [],
        circle: [],
        right: [],
      },
      entry: {
        role: 1,
        facility_id: 999999,
        work_days: [],
        space_number: 1,
      },
      apiKeyId: null,
      meta: {
        apartments:[],
        opening_time:[],
        closing_time:[],
      },
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id
    };
  },
  watch: {
    'entry.apartment_id': function(value, oldValue) {
      if (value != oldValue && value != '') {
        this.getFacilityList(value)
      }
    },
    "entry.work_days" : function (value, old) {
        if (this.startValidate) {
          if (this.entry.work_days.length) {
            this.$refs.work_days.reset()
          } else {
            this.$refs.work_days.validate()
          }
        }
    }
  },

  mounted: async function () {
    let responseBookMasters = await this.$request.get(this.ENDPOINT.UI_HELPER_BOOK_MASTERS);
    if(!responseBookMasters.hasErrors()) {
      let  apartments = responseBookMasters.data.apartments;
      apartments.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  opening_time = responseBookMasters.data.opening_time;
      opening_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  closing_time = responseBookMasters.data.closing_time;
      closing_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let  unit_time = responseBookMasters.data.unit_time;
      unit_time.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      this.meta = {
        apartments: apartments,
        opening_time: opening_time,
        closing_time: closing_time,
        unit_time: unit_time,
        facilities: [
          {id: null, name:this.$t('common.choose')},
        ],
      }
    }
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.BOOK_MASTERS_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.startValidate = true;
      }
    }
  },

  methods: {

    handleTestChange(e) {
      console.log(e.target.value);
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate() || !await this.$refs.work_days.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.BOOK_MASTERS_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.BOOK_MASTERS_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },
    getFacilityList(apartment_id) {
      if(apartment_id) {
        this.$request.get(this.ENDPOINT.UI_HELPER_BOOK_MASTERS_FACILITY_LIST(apartment_id)).then(res => {
          let  facilities = res.data.facilities;
          if(res.data.facilities) {
            facilities.unshift({id: null, name: this.$t('common.choose')});
            this.meta.facilities = facilities
          }
        })
      }
    },
    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.BOOK_MASTER_LIST, query: {
          'filters.apartment_id.equal': 'all',
          'filters.facility_id.equal': 'all',
        }
      });
    },
    handleDeleteBookMaster() {
      this.$refs.user_modal_delete.show();
    },
    async deleteUser() {
      const res = await this.$request.delete(this.ENDPOINT.BOOK_MASTERS_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.user_modal_delete.hide();
      }
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
